/**
 * @fileoverview Lazy implementation of Sentry Hub.
 * We lazy instantiate the Sentry hub to defer download the large sentry/browser
 * package.
 * We use the Sentry browser client instead of the default init method because
 * our sentry client should only cover the errors reported from the scope of the
 * widget.
 */

import type { EventHint, Scope } from '@sentry/browser'
import type { BrowserClientOptions } from '@sentry/browser/types/client'

import { addInboundFilter } from './eventProcessors/addInboundFilters'
import { addUserAgent } from './eventProcessors/addUserAgent'
import { sentryConfig } from './config'

let fwSentryConfig: FwSentryConfig | undefined
let fwSentry: FwSentry | undefined
let sentryScopePromise: Promise<Scope> | undefined

class FwSentryConfig {
  tags: Array<[string, string]> = []
  sampleRate: number | undefined = undefined
  tracesSampleRate: number | undefined = undefined
  profilesSampleRate: number | undefined = undefined
  replaysSampleRate: number | undefined = undefined
  replaysOnErrorSampleRate: number | undefined = undefined

  addTag(name: string, value: string) {
    this.tags.push([name, value])
  }

  setSampleRate(rate: number) {
    this.sampleRate = rate
  }

  setupTracing(tracesSampleRate: number) {
    this.tracesSampleRate = tracesSampleRate
  }

  setupProfiling(profilesSampleRate: number) {
    this.profilesSampleRate = profilesSampleRate
  }

  setupReplays(
    replaysSampleRate: number,
    replaysOnErrorSampleRate: number = 1,
  ) {
    this.replaysSampleRate = replaysSampleRate
    this.replaysOnErrorSampleRate = replaysOnErrorSampleRate
  }
}

/**
 *
 */
export class FwSentry {
  fwSentryConfig: FwSentryConfig

  /**
   *
   */
  constructor(config: FwSentryConfig) {
    this.fwSentryConfig = config
  }

  /** Client code should call `captureMessage` from logging.ts */
  async captureMessage(message: string, hint?: EventHint): Promise<void> {
    const scope = await this.#initSentryScope()
    scope.captureMessage(message, 'info', hint)
  }

  /** Client code should call `captureException` from logging.ts */
  async captureException(error: Error, hint?: EventHint): Promise<void> {
    const scope = await this.#initSentryScope()
    scope.captureException(error, hint)
  }

  /**
   * Disable lazy loading and initialize Sentry immediately.
   * This is required for performance monitoring to work
   */
  async disableLazyLoading(): Promise<void> {
    await this.#initSentryScope()
  }

  /**
   *
   */
  #initSentryScope(): Promise<Scope> {
    if (sentryScopePromise) {
      return sentryScopePromise
    }
    sentryScopePromise = import(
      /* webpackChunkName: 'sentry-browser' */ '@sentry/browser'
    ).then(
      ({
        BrowserClient,
        Scope,
        makeFetchTransport,
        defaultStackParser,
        breadcrumbsIntegration,
        globalHandlersIntegration,
        linkedErrorsIntegration,
        dedupeIntegration,
        browserTracingIntegration,
        browserProfilingIntegration,
        replayIntegration,
      }) => {
        const sentryScope = new Scope()

        const browserClientOptions: BrowserClientOptions = {
          transport: makeFetchTransport,
          stackParser: defaultStackParser,
          integrations: [
            breadcrumbsIntegration(),
            globalHandlersIntegration(),
            linkedErrorsIntegration(),
            dedupeIntegration(),
          ],
          ...sentryConfig,
        }

        if (this.fwSentryConfig.sampleRate) {
          browserClientOptions.sampleRate = this.fwSentryConfig.sampleRate
        }

        if (this.fwSentryConfig.tracesSampleRate) {
          browserClientOptions.tracesSampleRate =
            this.fwSentryConfig.tracesSampleRate
          browserClientOptions.integrations.push(browserTracingIntegration())
        }

        if (this.fwSentryConfig.profilesSampleRate) {
          browserClientOptions.profilesSampleRate =
            this.fwSentryConfig.profilesSampleRate
          browserClientOptions.integrations.push(browserProfilingIntegration())
        }

        if (this.fwSentryConfig.replaysSampleRate) {
          browserClientOptions.replaysSessionSampleRate =
            this.fwSentryConfig.replaysSampleRate
          browserClientOptions.replaysOnErrorSampleRate =
            this.fwSentryConfig.replaysOnErrorSampleRate
          browserClientOptions.integrations.push(replayIntegration())
        }

        const client = new BrowserClient(browserClientOptions)
        sentryScope.setClient(client)

        sentryScope.setTag('fwn_origin', window.location?.origin ?? '')
        this.fwSentryConfig.tags.forEach(([name, value]) =>
          sentryScope.setTag(name, value),
        )

        addUserAgent(sentryScope)
        addInboundFilter(sentryScope)

        client.init()
        return sentryScope
      },
    )
    return sentryScopePromise
  }
}

/**
 * @returns Sentry hub singleton instance
 */
export const getSentryConfig = (): FwSentryConfig => {
  if (!fwSentryConfig) {
    fwSentryConfig = new FwSentryConfig()
  }
  return fwSentryConfig
}

/**
 * @returns FW Sentry singleton
 */
export const getSentry = (): FwSentry => {
  if (!fwSentry) {
    fwSentry = new FwSentry(getSentryConfig())
  }
  return fwSentry
}

export const TEST_ONLY = {
  reset: (): void => {
    fwSentryConfig = undefined
    sentryScopePromise = undefined
  },
}
